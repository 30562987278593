import React, { useEffect, Suspense, lazy } from "react";
import ScrollToTop from "./helpers/scroll-top";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { ToastProvider } from "react-toast-notifications";
import { BreadcrumbsProvider } from "react-breadcrumbs-dynamic";

// home
const Home = lazy(() => import("./pages/home/HomeFlowerShop"));
const About = lazy(() => import("./pages/other/About"));
const ShopGridNoSidebar = lazy(() => import("./pages/shop/ShopGridNoSidebar"));

const ShopGridFullWidth = lazy(() => import("./pages/shop/ShopGridFullWidth"));
const ProductTabLeft = lazy(() =>
  import("./pages/shop-product/ProductTabLeft")
);
const BlogStandard = lazy(() => import("./pages/blog/BlogStandard"));
const BlogDetailsStandard = lazy(() =>
  import("./pages/blog/BlogDetailsStandard")
);
const LetsTalk = lazy(() => import("./pages/video/letstalk"));
const EducationalVideos = lazy(() => import("./pages/video/educationalVideos"));
const HappyTummyClub = lazy(() => import("./pages/other/HappyTummyClub"));
const FeedWithConfidence = lazy(() =>
  import("./pages/other/Feedwithconfidence")
);
const SustainabilityCommunity = lazy(() =>
  import("./pages/other/SustainabilityCommunity")
);
const Contact = lazy(() => import("./pages/other/Contact"));
const Faqs = lazy(() => import("./pages/other/Faqs"));
const BreasPpumpsFaqs = lazy(() => import("./pages/other/BreasPpumpsFaqs"));
const MyAccount = lazy(() => import("./pages/other/MyAccount"));
const LoginRegister = lazy(() => import("./pages/other/LoginRegister"));
const NotFound = lazy(() => import("./pages/other/NotFound"));
const PrivacyPolicy = lazy(() => import("./pages/other/PrivacyPolicy"));
const TermsConditions = lazy(() => import("./pages/other/TermsConditions"));
const HealthcareProfessionals = lazy(() =>
  import("./pages/other/healthcare/HealthcareProfessionals")
);
const HealthcareProfessionalsInTheKnow = lazy(() =>
  import("./pages/other/healthcare/HealthcareProfessionalsInTheKnow")
);
const HealthcareProfessionalsCPD = lazy(() =>
  import("./pages/other/healthcare/HealthcareProfessionalsCPD")
);
const HealthcareProfessionalsResources = lazy(() =>
  import("./pages/other/healthcare/HealthcareProfessionalsResources")
);
const Wishlist = lazy(() => import("./pages/other/Wishlist"));
const EducationSupport = lazy(() =>
  import("./pages/educationsupport/EducationSupport")
);
const Pregnancy = lazy(() => import("./pages/educationsupport/Pregnancy"));
const Breastfeeding = lazy(() =>
  import("./pages/educationsupport/Breastfeeding")
);
const BreastPumpsPumping = lazy(() =>
  import("./pages/educationsupport/BreastPumpsPumping")
);
const Lifestyle = lazy(() => import("./pages/educationsupport/Lifestyle"));
const Postnatal = lazy(() => import("./pages/educationsupport/Postnatal"));

const App = () => {
  useEffect(() => {
    const pathArray = window.location.pathname.split("/");
    let lang = "en";
    if (pathArray[1] && (pathArray[1] == "en" || pathArray[1] == "ar")) {
      lang = pathArray[1];
    }
    localStorage.setItem("currentLanguageCode", lang);
  }, []);

  return (
    <ToastProvider placement="bottom-left">
      <BreadcrumbsProvider>
        <Router>
          <ScrollToTop>
            <Suspense
              fallback={
                <div className="flone-preloader-wrapper">
                  <div className="flone-preloader">
                    <span></span>
                    <span></span>
                  </div>
                </div>
              }
            >
              <Switch>
                <Route
                  exact
                  path={process.env.PUBLIC_URL + "/"}
                  component={Home}
                />

                <Route
                  exact
                  path={process.env.PUBLIC_URL + "/ar"}
                  component={Home}
                />
                <Route
                  exact
                  path={process.env.PUBLIC_URL + "/en"}
                  component={Home}
                />

                <Route
                  path={process.env.PUBLIC_URL + "/en/products/:id"}
                  component={ShopGridNoSidebar}
                />

                <Route
                  path={process.env.PUBLIC_URL + "/ar/products/:id"}
                  component={ShopGridNoSidebar}
                />

                <Route
                  path={process.env.PUBLIC_URL + "/en/lets-talk"}
                  component={LetsTalk}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/ar/lets-talk"}
                  component={LetsTalk}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/en/educational-videos"}
                  component={EducationalVideos}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/ar/educational-videos"}
                  component={EducationalVideos}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/en/products"}
                  component={ShopGridFullWidth}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/ar/products"}
                  component={ShopGridFullWidth}
                />

                <Route
                  path={process.env.PUBLIC_URL + "/en/product/:id"}
                  component={ProductTabLeft}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/ar/product/:id"}
                  component={ProductTabLeft}
                />

                {/* Blog pages */}
                <Route
                  path={process.env.PUBLIC_URL + "/en/blogs"}
                  component={BlogStandard}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/ar/blogs"}
                  component={BlogStandard}
                />

                <Route
                  path={process.env.PUBLIC_URL + "/en/blogs/:id"}
                  component={BlogDetailsStandard}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/ar/blogs/:id"}
                  component={BlogDetailsStandard}
                />

                {/* Other pages */}
                <Route
                  path={process.env.PUBLIC_URL + "/en/our-story"}
                  component={About}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/ar/our-story"}
                  component={About}
                />

                <Route
                  path={process.env.PUBLIC_URL + "/en/happy-tummy-club"}
                  component={HappyTummyClub}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/ar/happy-tummy-club"}
                  component={HappyTummyClub}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/en/feed-with-confidence"}
                  component={FeedWithConfidence}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/ar/feed-with-confidence"}
                  component={FeedWithConfidence}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/en/sustainability-community"}
                  component={SustainabilityCommunity}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/ar/sustainability-community"}
                  component={SustainabilityCommunity}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/en/:id/:id"}
                  component={BlogDetailsStandard}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/ar/:id/:id"}
                  component={BlogDetailsStandard}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/en/contact"}
                  component={Contact}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/ar/contact"}
                  component={Contact}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/en/faqs"}
                  component={Faqs}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/ar/faqs"}
                  component={Faqs}
                />

                <Route
                  path={process.env.PUBLIC_URL + "/en/breast-pumps-faqs"}
                  component={BreasPpumpsFaqs}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/ar/breast-pumps-faqs"}
                  component={BreasPpumpsFaqs}
                />

                <Route
                  path={process.env.PUBLIC_URL + "/en/my-account"}
                  component={MyAccount}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/ar/my-account"}
                  component={MyAccount}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/en/login-register"}
                  component={LoginRegister}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/ar/login-register"}
                  component={LoginRegister}
                />

                <Route
                  path={process.env.PUBLIC_URL + "/en/wishlist"}
                  component={Wishlist}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/ar/wishlist"}
                  component={Wishlist}
                />

                <Route
                  path={process.env.PUBLIC_URL + "/en/not-found"}
                  component={NotFound}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/ar/not-found"}
                  component={NotFound}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/not-found"}
                  component={NotFound}
                />

                <Route
                  path={process.env.PUBLIC_URL + "/en/privacy-policy"}
                  component={PrivacyPolicy}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/ar/privacy-policy"}
                  component={PrivacyPolicy}
                />

                <Route
                  path={process.env.PUBLIC_URL + "/en/terms-and-conditions"}
                  component={TermsConditions}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/ar/terms-and-conditions"}
                  component={TermsConditions}
                />

                <Route
                  path={process.env.PUBLIC_URL + "/en/healthcare-professionals"}
                  component={HealthcareProfessionals}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/ar/healthcare-professionals"}
                  component={HealthcareProfessionals}
                />

                <Route
                  path={process.env.PUBLIC_URL + "/en/in-the-know-programme"}
                  component={HealthcareProfessionalsInTheKnow}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/ar/in-the-know-programme"}
                  component={HealthcareProfessionalsInTheKnow}
                />

                <Route
                  path={process.env.PUBLIC_URL + "/en/cpd-modules"}
                  component={HealthcareProfessionalsCPD}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/ar/cpd-modules"}
                  component={HealthcareProfessionalsCPD}
                />

                <Route
                  path={
                    process.env.PUBLIC_URL +
                    "/en/resources-for-professionals-breastfeeding"
                  }
                  component={HealthcareProfessionalsResources}
                />
                <Route
                  path={
                    process.env.PUBLIC_URL +
                    "/ar/resources-for-professionals-breastfeeding"
                  }
                  component={HealthcareProfessionalsResources}
                />

                <Route
                  path={process.env.PUBLIC_URL + "/en/education-support"}
                  component={EducationSupport}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/ar/education-support"}
                  component={EducationSupport}
                />

                <Route
                  path={process.env.PUBLIC_URL + "/en/pregnancy"}
                  component={Pregnancy}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/ar/pregnancy"}
                  component={Pregnancy}
                />

                <Route
                  path={process.env.PUBLIC_URL + "/en/breastfeeding"}
                  component={Breastfeeding}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/ar/breastfeeding"}
                  component={Breastfeeding}
                />

                <Route
                  path={process.env.PUBLIC_URL + "/en/breast-pumps-pumping"}
                  component={BreastPumpsPumping}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/ar/breast-pumps-pumping"}
                  component={BreastPumpsPumping}
                />

                <Route
                  path={process.env.PUBLIC_URL + "/en/lifestyle"}
                  component={Lifestyle}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/ar/lifestyle"}
                  component={Lifestyle}
                />

                <Route
                  path={process.env.PUBLIC_URL + "/en/maternal-mental-health"}
                  component={Postnatal}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/ar/maternal-mental-health"}
                  component={Postnatal}
                />

                <Route exact component={NotFound} />
              </Switch>
            </Suspense>
          </ScrollToTop>
        </Router>
      </BreadcrumbsProvider>
    </ToastProvider>
  );
};

export default App;
