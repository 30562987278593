import {
  ADD_TO_WISHLIST,
  DELETE_FROM_WISHLIST,
  DELETE_ALL_FROM_WISHLIST,
} from "../actions/wishlistActions";
import axios from "axios";

const initState = [];

const wishlistReducer = (state = initState, action) => {
  const wishlistItems = state,
    product = action.payload;

  if (action.type === ADD_TO_WISHLIST) {
    const wishlistItem = wishlistItems.filter(
      (item) => item._id === product._id
    )[0];

    if (wishlistItem === undefined) {
      let data = JSON.parse(localStorage.getItem("userData"));
      let formdata = new FormData();

      //formdata.append("user_id", data?.user?._id);
      formdata.append("product_id", product._id);
      axios
        .post(
          `https://api.lansinohmiddleeast.com/api/auth/wishlist`,
          formdata,
          {
            headers: {
              Authorization: `Bearer ${data.access_token}`,
            },
          }
        )
        .then((response) => {
          // console.log("response wishlist :: ", response)
        })
        .catch((error) => {
          console.log(error);
        });

      return [...wishlistItems, product];
    } else {
      return wishlistItems;
    }
  }

  if (action.type === DELETE_FROM_WISHLIST) {
    const remainingItems = (wishlistItems, product) =>
      wishlistItems.filter((wishlistItem) => wishlistItem._id !== product._id);
    return remainingItems(wishlistItems, product);
  }

  if (action.type === DELETE_ALL_FROM_WISHLIST) {
    return wishlistItems.filter((item) => {
      return false;
    });
  }

  return wishlistItems;
};

export default wishlistReducer;
